html {
  max-width: 100%;
  overflow-x: hidden;
}

*:hover {
  text-decoration: none !important;
  outline: none !important;
}

body {
  font-family: "Lato", sans-serif;
  background: #f9f9f9;
}
a {
  color: #444242;
  text-decoration: none;
}
:root {
  --red: red;
  --light_red: #f44336;
  --white: #f9f9f9;
  --skyblue: #33a3f1;
  --dark_orange: #f58021;
  --lightgreen: #74b430;
  --violet: #aa35d6;
  --dark_yellow: #d6ac1b;
  --dark_blue: #4c60f6;
  --dark_green: #32b47b;
  --wooden: #ab632e;
  --sub_text: #999999;
}
/* preloader */
.default-state{
  height: 100vh;
  overflow: hidden;
}
.default-state #preloader{
    display: block;
}
.default-state #displayed-content{
    opacity: 0;
}
.default-state.loaded{
  height: fit-content;
  overflow: auto;
}
.default-state.loaded #preloader{
    display: none;
}
.default-state.loaded #displayed-content{
    opacity: 1;
}
/* end preloader */
/* banner start */
.banner_img.for_dummy {
  opacity: 0;
  position: relative;
  z-index: 1;
}

.for_video .ban_para img {
  width: 20px;
  cursor: pointer;
}

.vid_playing {
  background: black;
}

.vid_playing .banner_heading {
  display: none;
}

.vid_playing svg,
.vid_playing i {
  display: none;
}

.for_video.vid_playing .ban_para img {
  width: 50px;
  cursor: pointer;
}

.for_video .ban_para img.video_paused {
  display: none;
}

.for_video .ban_para img.video_trigger {
  display: block;
}

.for_video.vid_playing .ban_para img.video_paused {
  display: block;
}

.for_video.vid_playing .ban_para img.video_trigger {
  display: none;
}

#ban_vid {
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-ban_big .slick-slide {
  margin: 0 0px;
}

.slider-ban_big.slick-slider {
  margin-bottom: 5px;
  margin-top: 0px;
}

.banner_img {
  width: 100%;
  position: relative;
  z-index: 1;
}

.no-gutters {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.row.banneront {
  width: 100%;
  margin: 0 auto;
}

.ban_image_wrapper {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
}

.ban_image_wrapper .hover_cont {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  top: unset;
  left: 0;
  z-index: 2;
  background: rgba(17, 17, 17, 0.95);
  background: linear-gradient(
    to bottom,
    rgba(17, 17, 17, 0) 0%,
    rgba(17, 17, 17, 0.1) 10%,
    rgba(17, 17, 17, 0.95) 100%
  );
  padding: 20px;
  transition: all 0.5s;
}

.banneront .col-md-2.no-gutters {
  width: 20%;
}

.banneront .col-md-6.no-gutters {
  width: 40%;
}

.clocksvg {
  width: 10px;
}

.ban_image_wrapper .clocksvg path {
  fill: white;
}

.ban_image_wrapper:hover .clocksvg path {
  fill: black;
}

.ban_image_wrapper .banner_heading {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  margin-bottom: 9px;
  margin-top: 14px;
  color: white;
  transition: all 0.5s;
}

.ban_image_wrapper:hover .banner_heading {
  color: #222222;
  transition: all 0.5s;
}

.small_banner_cont .banner_heading {
  font-size: 15px;
}

.large_banner_cont .banner_heading {
  font-size: 30px;
  font-weight: 900;
  line-height: 36px;
}

.banner_time i {
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--sub_text);
  margin-left: 6px;
}

.ban_image_wrapper .ban_para {
  font-size: 14px;
  margin-top: 1px;
  margin-bottom: 0;
  color: black;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s;
}

.ban_image_wrapper:hover .hover_cont {
  background: transparent;
  transition: all 0.5s;
}

.ban_image_wrapper:hover .ban_para {
  max-height: 56px;
  margin-top: 7px;
  transition: all 0.5s;
}

.ban_image_wrapper .hover_cont_outer {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: all 0.5s;
  cursor: pointer;
}

.ban_image_wrapper:hover .hover_cont_outer {
  background: #ffffff9c;
  transition: all 0.5s;
}

.banner_heading a {
  color: unset;
}

.banner_heading a:hover {
  color: var(--light_red);
  transition: all 0.5s;
}

/* banner end */
.slick-slide {
  margin: 0 20px;
  margin-left: 0px;
}

.heading hr {
  margin-top: 13px;
  margin-bottom: 25px;
  border-top: 1px solid #dad9d9;
}

.heading h2 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 32px;
}

.heading span {
  position: relative;
}

.heading span::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 30px;
  background-color: red;
}

.inner_tripura_featured {
  width: 100%;
  margin: 0 auto;
}

.tripura12_post_inner img {
  width: 29%;
  display: inline-block;
  float: left;
}

.tripura12_post_text_cont span i {
  font-size: 12px;
  letter-spacing: 0px;
  color: var(--sub_text);
  margin-left: 4px;
}

.archive-list > app-small-feature-block > .tripura12_post_text_cont {
  width: 100%;
  padding-left: 0px;
}

.tripura12_post_text_cont {
  width: 71%;
  display: inline-block;
  padding-left: 17px;
}

.tripura12_post_text_cont h3 {
  font-size: 15px;
  margin-top: 9px;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 21px;
  color: #333;
}

.tripura12_post_inner {
  margin-bottom: 20px;
}

.tripura12_post {
  width: 100%;
  margin: 0 auto;
}

.socialicon {
  width: 29%;
  background: red;
  display: inline-block;
  float: left;
  padding: 16px 18px;
  padding-top: 13px;
  padding-bottom: 18px;
  position: relative;
}

.socialicon::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: #436feb;
  top: calc(50% - 3px);
  right: -3px;
  transform: rotate(45deg);
}

.socialicon img {
  width: 17px;
}

.social.followers {
  width: 71%;
  display: inline-block;
  background: #efeeee;
  padding-left: 12px;
}

.social.followers .subscriber {
  margin: 0;
  margin-top: 7px;
  margin-bottom: -2px;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0px;
}

.social.followers .static_text {
  font-size: 11px;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.social_cont.facebook .socialicon {
  background: #436feb;
}

.social_cont.facebook .socialicon::before {
  background: #436feb;
}

.social_cont.rss .socialicon {
  background: #f0771e;
}

.social_cont.rss .socialicon::before {
  background: #f0771e;
}

.social_cont.twitter .socialicon {
  background: #43c9ec;
}

.social_cont.twitter .socialicon::before {
  background: #43c9ec;
}

.social_cont.google_plus .socialicon {
  background: #f04131;
}

.social_cont.google_plus .socialicon::before {
  background: #f04131;
}

.social_cont {
  margin-top: -1px;
  margin-bottom: 20px;
}

.google_ads {
  width: 100%;
}

.social_googleads_news_cont {
  width: 100%;
  margin: 0 auto;
  float: left;
  position: sticky;
  top: 50px;
}

.social_cont_outer {
  width: 100%;
  margin: 0 auto;
}

.social_cont:nth-child(1),
.social_cont:nth-child(3) {
  padding-left: 0;
}

.social_cont:nth-child(2),
.social_cont:nth-child(4) {
  padding-right: 0;
}

.outerunderbanner_row {
  width: 100%;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  display: block;
}

.small_banner_cont {
  position: relative;
}

.gallery_start {
  width: 100%;
  margin: 0 auto;
}

.hover_info_gallery {
  width: 100%;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  background: #060606a8;
  transition: all 0.5s;
}
.fashion_outer  .hover_info_gallery {
  width: 100%;
  padding: 5px 20px 6px;
  position: static;
  bottom: 0;
  background: #060606a8;
  transition: all 0.5s;
}
.slider-gallery .small_banner_cont:hover .hover_info_gallery,
.slider-gallery-for_world_news .small_banner_cont:hover .hover_info_gallery,
.slider-you-may-also-like .small_banner_cont:hover .hover_info_gallery {
  background: #f44336;
  transition: all 0.5s;
}

.hover_info_gallery p {
  color: white;
}

.hover_info_gallery svg {
  fill: white;
}

.hover_info_gallery i {
  color: white;
}

/* Fashion business and sports start */
.fashion_lifestyle_sportscont {
  width: 100%;
  margin: 0 auto;
}

.slider.fashion {
  width: 100%;
  margin: 0 auto;
}

.fashionbanner {
  width: 1000%;
}

.fashionbanner img {
  width: 100%;
}

.slick-slide.fashionbanner {
  margin: auto;
  position: relative;
}

.slick-slide.fashionbanner .hover_info_gallery {
  width: 100%;
  bottom: 41%;
  text-align: center;
  height: 82px;
}

.slick-slide.fashionbanner .hover_info_gallery .banner_time img {
  display: inline-block;
  width: 10px;
}

.inner_tripura_featured .slider-gallery {
  margin-bottom: 0px;
  cursor: pointer;
}

.slick-slider .slick-track {
  float: left;
}

.fashion_outer {
  position: relative;
}

.fashion_outer .slick-dots {
  bottom: -45px;
  width: 100%;
  list-style: none;
  text-align: center;
  position: absolute;
  bottom: 85px;
}

.fashion_outer .slick-dots li button {
  width: 7px;
  height: 7px;
  opacity: 1;
  background: white;
  border-radius: 100px;
}

.slick-dots .slick-active button {
  background: #f44336 !important;
}

.sports_outer .tripura12_post_inner img,
.business_outer .tripura12_post_inner img {
  width: 21.5%;
  display: inline-block;
  float: left;
}

.sports_outer .tripura12_post_inner .tripura12_post_text_cont h3,
.business_outer .tripura12_post_inner .tripura12_post_text_cont h3 {
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 6px;
  font-weight: 700;
  line-height: 16px;
  color: #333;
}

.sports_outer .tripura12_post_inner .tripura12_post_text_cont,
.business_outer .tripura12_post_inner .tripura12_post_text_cont {
  width: 78%;
}

/* Fashion business and sports end */
/* featured video start */
.fetured_video .heading h2 {
  color: #f44336;
}

.fetured_video {
  background: #222222;
  padding-bottom: 40px;
}

.fetured_video .slick-arrow {
  display: none !important;
}

.inner_tripura_featured_video {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.banner_time .clock {
  width: 10px;
  opacity: 0.7;
}

.india_cont > img {
  width: 100%;
}

.india_info {
  width: 100%;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0px 5px 6px 0px #00000008;
  min-height: 114px;
}

.row.india_cont {
  width: 100%;
  margin: 0 auto;
}

.india_cont {
  padding-left: 0;
  margin-bottom: 19px;
  /* cursor: pointer; */
}
.row .india_cont{
  cursor: pointer;
}
/* world news cont */
.world_news {
  background: white;
  margin: 0 auto;
  margin-top: 34px;
}

/* world news cont end */
/* secondery start */
/* secondery end */
.row.innerunderbanner_row {
  width: 100%;
  margin: 0 auto;
}

.heading.secondery h2 {
  font-size: 22px;
  line-height: 25px;
  font-weight: 700;
  color: #756e6e;
}

.heading.secondery span:before {
  display: none;
}

.heading.secondery .secondery_datetime img {
  width: 10px;
}

.heading.secondery .secondery_datetime i {
  font-size: 12px;
  letter-spacing: 0px;
  color: var(--sub_text);
  margin-left: 4px;
}

.secondery_banner_and_text_cont {
  width: 100%;
  margin: 0 auto;
}

.secondery_banner_and_text_cont img {
  width: 100%;
  margin-bottom: 30px;
}

.quote {
  width: 100%;
  margin: 30px auto;
  background: #222222;
  padding: 40px 40px 40px 84px;
  position: relative;
  color: white;
  font-size: 17.5px;
  font-weight: 400;
}

.quote img {
  width: 39px;
  position: absolute;
  left: 20px;
  opacity: 0.8;
}

img.full_width_image {
  width: 100%;
  margin: 30px auto;
}

.social_news {
  width: 50px;
  display: inline-block;
  background: red;
}

.share_this {
  width: 100%;
  font-weight: 700;
  font-weight: 700;
  margin: 20px auto;
  border-top: 1px solid #dad9d9;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dad9d9;
}

.social_news img {
  width: 100%;
}

.social_news {
  padding: 7px 7px;
  width: 37px;
  display: inline-block;
  background: #222222;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 6px;
  transition: all 0.3s;
}

.social_news:hover {
  transition: all 0.3s;
  background: #ff0000;
}

.for_secpndery_page_side_bar .tripura12_post_text_cont img {
  width: 10px;
  margin-top: 6px;
}

/* secondery end */
/* archive start */
.container--tabs .nav-tabs {
  float: left;
  width: 100%;
  margin: 0;
  list-style-type: none;
  border-bottom: 0px solid #ddd;
}

.container--tabs .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.container--tabs .nav-tabs > li > a {
  font-size: 12px;
  float: left;
  margin-right: 0px;
  line-height: 1.42857143;
  letter-spacing: 1px;
  padding: 10px;
  border: 0px solid transparent;
  border-radius: 0px 0px 0 0;
  color: #ff0000;
  font-weight: 700;
  text-transform: uppercase;
}

.container--tabs .nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.container--tabs .nav-tabs > li.active > a,
.container--tabs .nav-tabs > li.active > a:hover,
.container--tabs .nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #efefef;
  border: 0px solid #ddd;
  border-bottom-color: transparent;
}

.container--tabs .tab-content {
  float: left;
  width: 100%;
}

.container--tabs .tab-content > .tab-pane {
  display: none;
}

.container--tabs .tab-content > .tab-pane.active {
  display: block;
  background-color: #efefef;
  padding: 20px;
}

.container--tabs .tab-content > .active {
  display: block;
}

.row.tab-pane {
  width: 100%;
  margin: 0 auto;
}

.archive_cont ul {
  padding: 0;
  list-style: none;
  text-align: left;
}

.archive_cont ul li {
  color: white;
  padding: 0;
  list-style: none;
  width: auto;
  background: #3a3838;
  display: inline-block;
  padding: 5px 16px;
  margin-bottom: 7px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.5s;
}

.archive_cont ul li:hover {
  background: #fb3838;
  transition: all 0.5s;
}

.archive_cont {
  overflow: auto;
  padding: 25px 17px 10px;
  font-size: 12px;
}

/* archive end */
.ban_image_wrapper.for_video .banner_img {
  position: absolute;
  z-index: 2;
  height: 100%;
  right: 0;
  top: 0;
}

.ban_image_wrapper.for_video .banner_img.for_dummy {
  position: relative;
  z-index: 1;
}

.ban_image_wrapper.for_video.vid_playing .banner_img {
  display: none;
}

.ban_image_wrapper.for_video.vid_playing .banner_img.for_dummy {
  display: block;
}
.ban_image_wrapper.for_video.vid_playing iframe,
.ban_image_wrapper.for_video.vid_playing video {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}

.row {
  margin: 0 auto;
  width: 100%;
}

.ban_image_wrapper.for_video {
  background: black;
}

.static_sidebar_cont .heading h2 {
  margin-top: 0px;
}

.world_news.world_news_dark{
  background: #222222;
  padding-bottom: 40px;
}

.world_news.world_news_dark .heading{
  color: #f44336;
}

/* .owl-carousel .owl-item{
  padding: 8px;
} */