@media only screen and (max-width: 991px) and (min-width: 300px) {
  
  
  .banneront .small_banner_cont .ban_image_wrapper {
    display: inline-block;
    width: 50%;
  }
  .banneront .leftside_last_ban_col .ban_image_wrapper {
    width: 100%;
  }
  .static_sidebar_cont .heading h2 {
    margin-top: 32px;
  }
  .ticker_message_cont {
    padding: 0;
  }
  .container {
    padding-right: 7px;
    padding-left: 7px;
  }
  .banneront .col-md-2.no-gutters {
    display: inline-block;
    width: 100%;
  }
  .banneront .col-md-6.no-gutters {
    width: 100%;
    margin-bottom: 3px;
  }
  .small_banner_cont .banner_heading {
    font-size: 10px;
    line-height: normal;
    margin-top: 6px;
    margin-bottom: 0px;
  }
  .banner_time i {
    font-size: 10px;
    margin-left: 2px;
  }
  .banneront .small_banner_cont .ban_image_wrapper.for_video {
    width: 100%;
    /* margin-top: 5px; */
  }
  .for_small_bannr_slide .slick-arrow {
    top: 38%;
  }
  .clocksvg {
    width: 8px;
  }
  .vid_playing .hover_cont_outer {
    height: 0px;
    bottom: 0;
    top: 100%;
  }
  .ban_image_wrapper .ban_para {
    font-size: 10px;
    margin-bottom: 0;
    color: black;
    overflow: hidden;
  }
  .banneront .ban_image_wrapper:hover .ban_para {
    max-height: 50px;
    margin-top: 3px;
    transition: all 0.5s;
  }
  .banneront .leftside_last_ban_col .banner_heading,
  .banneront .large_banner_cont .banner_heading {
    font-size: 18px;
    font-weight: 900;
    line-height: initial;
  }
  .banneront .leftside_last_ban_col .ban_image_wrapper .ban_para,
  .banneront .large_banner_cont .ban_image_wrapper .ban_para {
    font-size: 13px;
  }
  .ban_image_wrapper .hover_cont {
    padding: 10px;
  }
  .rightside_ban_col > app-block-selection {
    display: inline-block;
    width: 50%;
    float: left;
  }
  .rightside_ban_col > app-block-selection {
    width: 49%;
  }
  .rightside_ban_col app-block-selection:nth-child(1) {
    margin-right: 2%;
  }

  .rightside_ban_col > app-slider-block {
    display: inline-block;
    width: 50%;
    background: red;
  }
  .leftside_ban_col > app-block-selection {
    display: inline-block;
    width: 50%;
    float: left;
  }

  .leftside_ban_col > app-block-selection {
    width: 49%;
  }
  .leftside_ban_col app-block-selection:nth-child(1) {
    margin-right: 2%;
  }

  .leftside_ban_col > app-slider-block {
    display: inline-block;
    width: 50%;
    background: red;
  }
  .banneront .small_banner_cont app-block-selection .ban_image_wrapper {
    width: 100%;
  }
  .tripura_cont .slick-slide {
    margin: 0 7px;
    margin-left: 0px;
  }
  .tripura12_post_text_cont span i {
    font-size: 10px;
  }
  .inner_tripura_featured .ban_image_wrapper {
    width: 95%;
    margin: 0 auto;
  }
  /* 12 post start*/
  .tripura12_post_inner {
    margin-bottom: 3px;
    padding: 5px;
    z-index: 99;
  }
  .tripura12_post_text_cont {
    width: 100%;
    padding-left: 0px;
    min-height: 90px;
  }

  app-small-life-style
    > .tripura12_post_inner
    > app-small-feature-block
    > .tripura12_post_text_cont {
    min-height: fit-content;
  }

  app-search-page
    > .outerunderbanner_row
    app-small-feature-block
    > .tripura12_post_text_cont,
  app-archive-detail
    > .outerunderbanner_row
    .archive-list
    app-small-feature-block
    > .tripura12_post_text_cont {
    min-height: 120px;
  }

  .archive-list > app-small-feature-block > .tripura12_post_text_cont h3 {
    font-size: 14px;
    font-weight: 400;
  }
  .tripura12_post_text_cont h3 {
    font-size: 12px;
    margin-top: 9px;
    margin-bottom: 8px;
    font-weight: 300;
    line-height: inherit;
    letter-spacing: 0px;
    color: #333;
  }
  .tripura12_post_inner img {
    width: 100%;
    display: inline-block;
    float: left;
  }
  .social_height {
    height: auto !important;
    padding-right: 0;
    padding-left: 0;
  }
  /* 12 post end */

  /* social start */
  .social.followers .subscriber {
    margin: 0;
    margin-top: 7px;
    margin-bottom: -1px;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0px;
  }
  .social.followers .static_text {
    font-size: 10px;
  }
  .socialicon {
    padding: 12px 10px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .social_cont {
    margin-bottom: 11px;
  }
  .heading hr {
    margin-bottom: 15px;
  }
  /* social end */

  /* fashion sports business */
  .sports_outer .tripura12_post_inner .tripura12_post_text_cont,
  .business_outer .tripura12_post_inner .tripura12_post_text_cont {
    width: 78%;
    padding-left: 20px;
  }
  .sports_outer,
  .business_outer {
    padding: 0;
  }
  /* fashion sports business end*/

  /* featured video start */
  .fetured_video {
    background: #222222;
    width: 100%;
    margin: 0 auto;
  }
  .india_cont {
    padding-left: 0;
    padding-right: 0;
  }
  /* featuren video end */

  /* world news */
  .world_news {
    margin-top: 15px;
  }
  .slick-slide {
    margin: 0 3px;
  }
  .hover_info_gallery {
    padding: 10px 7px;
  }

  /* india start */
  .india_cont {
    padding-left: 0;
    padding-right: 4px;
  }
  .india_info {
    padding: 20px 10px;
    min-height: 150px;
  }
  .menucont.show_menu {
    display: block;
    position: fixed;
    top: 0;
    background: #c50707;
    z-index: 7;
    bottom: 0;
    padding: 10px;
    padding-left: 10px;
    padding-top: 100px;
    width: 100%;
    left: 0;
    height: 100%;
    overflow-y: auto;
  }

  .menucont.show_menu .nav_outer_for_nav ul li {
    display: inline;
    /* background: red; */
    padding: 16px 10px;
    margin-right: 2px;
    float: left;
    display: block;
    width: 100%;
  }
  .nav_outer_for_nav ul li {
    padding: 9px 10px;
    font-size: 17px;
  }
  .hamburger_cont label {
    display: flex;
  }

  .row.nav_outer_for_nav.fixed {
    display: block;
    position: static;
  }
  .nav_outer_for_nav ul li.close_nav {
    position: absolute;
    top: 13px;
    width: 20px;
    right: calc(0% - -20px);
    font-size: 25px;
  }
  .row.india_cont {
    width: 100%;
    margin: 0 auto;
    padding-right: 0px;
  }
  .india_cont:nth-child(even) {
    padding-right: 0px;
  }
  /* india end */
  .innerunderbanner_row .col-md-8.tripura_cont {
    padding-right: 0;
    padding-left: 0;
  }
  .nav_outer_for_nav ul li.close_nav {
    display: block;
  }
  .search_cont button {
    display: block;
  }
  .nav_outer_for_logo .social_cont {
    display: none;
  }
  .ban_image_wrapper {
    margin-bottom: 0px;
  }
  .slider-ban_big.slick-slider {
    margin-bottom: 0px;
  }
  .search_cont {
    display: inline-block;
  }

  .slick-slide.fashionbanner .hover_info_gallery {
    height: 90px;
  }
  .fashion_outer .slick-dots {
    bottom: 95px;
  }
}

@media only screen and (max-width: 9999px) and (min-width: 991px) {
  .banneront .col-md-2.no-gutters.rightside_ban_col {
    width: calc(20% - 8px);
    margin-right: 5px;
  }
  .banneront .col-md-6.no-gutters.big_mid_ban_col {
    width: calc(40% - 10px);
    margin-right: 5px;
  }
  .banneront .col-md-2.no-gutters.leftside_ban_col {
    width: calc(20% - 8px);
    margin-right: 5px;
    /* margin-bottom: 5px; */
  }
  .banneront .col-md-2.no-gutters.leftside_last_ban_col {
    width: calc(20% - 8px);
    margin-right: 5px;
  }

  .inner_tripura_featured.category-list
    .col-md-6.col-xs-6.small_banner_cont.no-gutters
    app-small-blocks {
    width: 100%;
  }
  .inner_tripura_featured.category-list
    .col-md-6.col-xs-6.small_banner_cont.no-gutters
    app-small-blocks:nth-child(1) {
    width: 99%;
    float: left;
  }
}
@media only screen and (max-width: 346px) and (min-width: 300px) {
  .inner_ban_input_cont {
    display: none;
  }
}
